<template>
  <div>
    <layout-app-header class="z-30" />
    <main>
      <section class="page-offset lg:grid-base container mb-24 lg:grid-cols-6">
        <div class="bg-sand p-10 lg:col-start-2 lg:col-end-6 lg:p-24">
          <slot />
        </div>
      </section>
    </main>
    <layout-app-footer />
  </div>
</template>
